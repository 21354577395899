import React, {Component} from 'react'
import RatingStars from './RatingStars';
import RatingTripadvisor from './RatingTripadvisor';
import Tippy from "@tippyjs/react";
import {SecretPriceReveal} from "./SecretPriceReveal";
import {useSelector} from "react-redux";
import MfhLogo from "../assets/svg/MfhLogo";
import District from "../assets/svg/District";
import {HotelBadgeSavings} from "./HotelBadgeSavings";
import {HotelBadgeDiscount} from "./HotelBadgeDiscount";
import {Link} from "react-router-dom";
import {createUrlWithParams} from "../functions/url";

export const HotelListEntry = (props) => {

  const {hotel, highlightHotel, setHighlightHotel} = props

  const isLoggedIn = useSelector(state => state.login.isLoggedIn)
  const searchCity = useSelector(state => state.search.searchCity)
  const product = useSelector(state => state.search.searchProduct)

  let mediaUuid = ''
  let mediaUrl = '/hotel-room-icon-03.png'
  if (Array.isArray(hotel.BookingMedia) && hotel.BookingMedia.length) {
    mediaUuid = hotel.BookingMedia.filter(picture => picture.position === '1')[0]?.uuid || hotel.BookingMedia[0]?.uuid
    if (mediaUuid) mediaUrl = '/api/booking/media/' + mediaUuid +'?width=600'
  }
  const highlight = (hotel.hotelId === highlightHotel)
  const membersOnly = hotel.membersOnly
  const priceHidden = membersOnly && !isLoggedIn
  const district = (hotel.hotelOfferType === 'district')
  const yearScope = (hotel.nightsScope === 'year')

  //const detailUrl = '/hotel/' + searchCity.cityId + '/' + hotel.hotelId + '/' + hotel.nightsPerMonth +'/'
  const baseUrl = '/hotel/' + searchCity.cityId + '/' + hotel.hotelId + '/'
  const detailUrl = createUrlWithParams(baseUrl, {nights: hotel.nights, product: product})

  class CustomLink extends Component {
    render () {
      if(priceHidden){
        return <div className={this.props.divClass}>{this.props.children}</div>
      }
      return <Link to={this.props.to} className={this.props.linkClass}>{this.props.children}</Link>
    }
  }

  return (
    <>
      {/* Desktop & Tablet */}
      <div
        className={`rounded-lg mb-6 shadow-sm ${highlight ? 'bg-gray-100':'bg-gray-50'} ${district ? 'border-district border-2':''} border-gray-200 border hidden sm:flex sm:items-stretch`}
        onMouseEnter={() => setHighlightHotel(hotel.hotelId)}
        onMouseLeave={() => setHighlightHotel(null)}
      >
        <CustomLink to={detailUrl} linkClass="sm:flex sm:items-stretch" divClass="sm:flex sm:items-stretch">
          <div
            className={`w-56 lg:w-64 xl:w-72 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden hover:opacity-90 relative`}
            style={{backgroundImage: `url(${mediaUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
            title={hotel.name}>

            {/* Image Ribbon */}
            <div className="absolute left-0 top-0">
              {membersOnly
                ? <Tippy theme="default" content={
                  <div>Dieses Angebot ist nur für registrierte MyFlexHome Mitglieder sichtbar.</div>
                }>
                  <div className="bg-membersOnly bg-opacity-90 rounded-lg text-left text-white text-xs py-1 px-3 m-2 flex items-center">
                    <MfhLogo />
                    <span className="ml-1">Members only</span>
                  </div>
                </Tippy>
                : ''
              }
            </div>
            <div className={`absolute left-0 ${membersOnly ? 'top-7' : 'top-0'}`}>
              {hotel?.imageRibbon
                ? <div className="bg-black bg-opacity-50 rounded-lg text-left text-white text-xs py-1 px-3 m-2">
                  {hotel.imageRibbon}
                </div>
                : ''
              }
            </div>
          </div>
        </CustomLink>

        <div className="m-2 md:m-3 text-left w-full">

          <div>
            <div className="text-xs text-gray-500 font-light mb-1">{hotel.addressCity || ''} - {props?.hotel?.addressDistrict || ''}</div>
          </div>

          <div className="mb-3">
            <div className="leading-5 text-lg font-regular mr-1">
              <CustomLink to={detailUrl} linkClass="hover:underline">{hotel.name}</CustomLink>
              <RatingStars rating={hotel.ratingStars || 0} classes={'inline-flex relative -top-1 left-1'} />
            </div>
          </div>

          <div className="flex">
            <div className="w-1/2 font-light">
              {district
                ? ''
                : <CustomLink to={detailUrl} linkClass="hover:underline">
                  <div className="text-sm">{hotel.addressStreet}</div>
                  <div className="text-sm">{hotel.addressZip} {hotel.addressCity}</div>
                </CustomLink>
              }
              {hotel?.BookingDistrict?.description ? <div className="text-sm">{hotel.BookingDistrict.description}</div> : '' }
            </div>
            <div className="w-1/2">
              {hotel?.ratingTripadvisor ? <div className="text-sm flex flex-wrap justify-end">
                <p className="mr-1 mb-1 text-xs text-gray-600">Bewertung</p>
                <RatingTripadvisor rating={hotel.ratingTripadvisor || 0} uri={hotel.ratingTripadvisorUri} />
              </div> : ''}

            </div>
          </div>

          <div className="flex mt-4">
            <div className="w-1/2">
              {district
                ? <div className="flex items-center text-sm text-primaryHover">
                  <div className="ml-1 mr-2"><District fill="#1a91b6" /></div>
                  Stadtteil-Abo
                </div>
                : ''
              }
              {hotel.BookingHotelAttributes.map(x => (x.category === 'super') ? <div key={x.uuid}>
                  <div className="flex items-center text-sm">
                    <img src={'/api/booking/media/' + x.uuid} alt={x.name} className="pt-1 w-7 h-7 mr-1" /> {x.name}
                  </div>
                </div> : ''
              )}
            </div>
            <div className="w-1/2">
              {priceHidden
                ? <SecretPriceReveal hotel={hotel} />
                : null
              }
              <div className={`w-full text-right ${priceHidden ? 'blur-sm select-none z-10' : ''}`}>
                {hotel.discountPerNight > 0 && !priceHidden
                  ? <HotelBadgeDiscount hotel={hotel} align={'justify-end my-1'} />
                  : ''}
                {hotel.savingsInPercent > 5 && !priceHidden
                  ? <HotelBadgeSavings hotel={hotel} align={'justify-end my-1'} />
                  : ''}
                <CustomLink to={detailUrl} linkClass="hover:underline">
                  <div className="text-xl font-bold">
                    <span className="text-sm font-light mr-1"></span>
                    {hotel.discountPerNight > 0
                      ? <>
                        <span className="text-base font-bold text-red-700 tracking-tight line-through mr-1">
                          {hotel?.pricePerNightStandardRoom.toFixed(0)} €
                        </span>
                        <span>
                          {hotel.pricePerNightWithDiscount.toFixed(0)} € <span className="font-light text-lg">/ Nacht</span>
                        </span>
                      </>
                      : <>
                        <span>
                          {hotel.pricePerNightStandardRoom.toFixed(0)} € <span className="font-light text-lg">/ Nacht</span>
                        </span>
                      </>
                    }
                  </div>
                  {yearScope
                    ? ''
                    : product === 'business'
                      ? <div className="text-xs font-light">im Abo - ab {hotel.nights ?? 4} Nächte pro Monat</div>
                      : <div className="text-xs font-light">im {hotel.nights ?? 4}-Nächte Abo</div>
                  }
                </CustomLink>
                {yearScope
                  ? ''
                  : <div className="text-sm text-secondaryGray mt-3 font-light">
                      {product === 'business'
                        ? <span className="text-xs text-right">ab</span>
                        : <span className="text-xs text-right">entspricht</span>
                      }
                      {hotel.discountPerMonth > 0
                        ? <>
                          <span className="tracking-tight text-red-700 line-through ml-1">
                            {hotel?.pricePerMonthStandardRoom.toFixed(0)} €
                          </span>
                          <span className="ml-1">
                              {hotel.pricePerMonthWithDiscount.toFixed(0)} € / Monat
                          </span>
                        </>
                        : <>
                          <span className="ml-1">
                            {hotel.pricePerMonthStandardRoom.toFixed(0)} € / Monat
                          </span>
                        </>
                      }
                    </div>
                }
              </div>
            </div>
          </div>

        </div>
      </div>


      {/* Mobile */}
      <div className="sm:hidden mb-10">
        <CustomLink to={detailUrl}>
          <div
            className="h-48 w-full flex-none bg-cover rounded-lg overflow-hidden relative"
            style={{backgroundImage: `url(${mediaUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
            title={hotel.name}>

            {/* Image Ribbon */}
            <div className="absolute left-0 top-0">
              {membersOnly
                ? <div className="bg-secondaryBlue bg-opacity-90 rounded-lg text-left text-white text-xs py-1 px-3 m-2 flex items-center">
                  <MfhLogo />
                  <span className="ml-1">Members only</span>
                </div>
                : ''
              }
            </div>
            <div className={`absolute left-0 ${membersOnly ? 'top-7' : 'top-0'}`}>
              {hotel?.imageRibbon
                ? <div className="bg-black bg-opacity-50 rounded-lg text-left text-white text-xs py-1 px-3 m-2">
                  {hotel.imageRibbon}
                </div>
                : ''
              }
            </div>
          </div>
        </CustomLink>

        <div
          className="text-xs text-gray-500 font-light mt-1 mb-1">{hotel.addressCity || ''} - {props?.hotel?.addressDistrict || ''}</div>

        <div>
          <div className="leading-5 text-xl font-light mr-1 mb-1 hover:underline">
            <CustomLink to={detailUrl} linkClass="hover:underline">{hotel.name}</CustomLink>
            <RatingStars rating={hotel.ratingStars || 0} classes={'inline-flex relative -top-1 left-1'} />
          </div>
        </div>

        <div id="priceBox" className={`${priceHidden ? 'blur-sm select-none z-10' : ''} mt-2`}>
          <div className="flex">
            {hotel.discountPerNight > 0 && !priceHidden
              ? <HotelBadgeDiscount hotel={hotel} />
              : ''}
            {hotel.savingsInPercent > 5 && !priceHidden
              ? <HotelBadgeSavings hotel={hotel} />
              : ''}
          </div>

          <div className={`flex justify-between items-start`}>

            <CustomLink to={detailUrl} linkClass="hover:underline">
            <div className="text-xl font-bold">
              {hotel.discountPerNight > 0
                ? <>
                  <span className="text-base font-bold text-red-700 tracking-tight line-through mr-1">
                    {hotel?.pricePerNightStandardRoom.toFixed(0)} €
                  </span>
                  <span>
                    {hotel.pricePerNightWithDiscount.toFixed(0)} € <span className="font-light text-lg">/ Nacht</span>
                  </span>
                </>
                : <>
                  <span>{hotel.pricePerNightStandardRoom.toFixed(0)} € <span className="font-light text-lg">/ Nacht</span>
                  </span>
                </>
              }
              {yearScope
                ? ''
                : product === 'business'
                  ? <div className="text-xs font-light">im Abo - ab {hotel.nights ?? 4} Nächte pro Monat</div>
                  : <div className="text-xs font-light">im {hotel.nights ?? 4}-Nächte Abo</div>
              }
            </div>
            </CustomLink>

            {yearScope
              ? ''
              : <div className="text-xs text-secondaryGray mt-1 font-light h-6 flex items-center">
                {product === 'business'
                  ? <span className="text-xs text-right">ab</span>
                  : <span className="text-xs text-right">entspricht</span>
                }
                {hotel.discountPerMonth > 0
                  ? <>
                    <span className="tracking-tight text-red-700 line-through ml-1">
                      {hotel?.pricePerMonthStandardRoom.toFixed(0)} €
                    </span>
                    <span className="ml-1">
                      {hotel.pricePerMonthWithDiscount.toFixed(0)} € / Monat
                    </span>
                  </>
                  : <>
                    <span className="ml-1">
                      {hotel.pricePerMonthStandardRoom.toFixed(0)} € / Monat
                    </span>
                  </>
                }
              </div>
            }
          </div>

        </div>
        {priceHidden
          ? <div className="-mt-6"><SecretPriceReveal hotel={hotel} /></div>
          : null
        }

      </div>
    </>

  )
}
