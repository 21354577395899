import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NoHotelFound} from '../components/NoHotelFound';
import {HotelList} from '../components/HotelList';
import LoadingSpinner from "../assets/svg/LoadingSpinner";
import {MapsWindowSearch} from "../components/MapsWindowSearch";
import {Helmet, HelmetProvider} from "react-helmet-async";
import * as actions from "../store/actions";
import {useParams} from "react-router-dom";
import {useLocation} from "react-router";

const Search = () => {

  const dispatch = useDispatch()
  const { city } = useParams()

  const citiesLoading = useSelector(state => state.search.citiesLoading)
  const hotelsLoading = useSelector(state => state.search.hotelsLoading)
  const hotelList = useSelector(state => state.search.hotelList)
  const searchFilter = useSelector(state => state.search.searchFilter)
  const searchCity = useSelector(state => state.search.searchCity)

  const nights = useSelector(state => state.search.searchNights)
  const product = useSelector(state => state.search.searchProduct)
  const nightListCurrentProduct = useSelector(state => state.search.nightListCurrentProduct)
  const nightsLoaded = nightListCurrentProduct.length > 0
  // derive scope from night list by number of nights, assumes uniqueness
  const nightsScope = nightListCurrentProduct.find(x => x.n === nights)?.s || ''

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const sort = searchQuery.get('sort') || ''
  const order = searchQuery.get('order') || ''
  const filter = searchQuery.get('filter') || ''
  const cache = searchQuery.get('cache') || ''

  useEffect(() => {
    if(nightsLoaded) {
      dispatch(actions.getAllHotels(city, nights, nightsScope, product, sort, order, filter, cache))
    }
  }, [dispatch, city, nights, nightsScope, product, sort, order, filter, cache, nightsLoaded])

  const [highlightHotel, setHighlightHotel] = useState(null)

  return (
    <section className="city">
      <HelmetProvider>
        <Helmet>
          <title>Hotels in {searchCity?.cityName || ''} | MyFlexHome</title>
          <meta name="description" content="Hotel-Abo" />
        </Helmet>
      </HelmetProvider>
      <div className="viewSearch__container">
        <div className="viewSearch__map" id="cityMap">
          <div className="sm:hidden mx-6 mb-5 text-lg font-bold">Karte</div>
          { citiesLoading
            ? <div className="flex justify-center my-44"><LoadingSpinner /></div>
            : <div className="viewSearch__mapbox">
              <MapsWindowSearch
                highlightHotel={highlightHotel}
                setHighlightHotel={setHighlightHotel}
              />
            </div>
          }
        </div>

        <div className="viewSearch__content">
          { hotelsLoading
              ? <div className="flex justify-center my-44"><LoadingSpinner /></div>
              : <>
              { hotelList.length >= 1 || searchFilter
                ? <HotelList
                    highlightHotel={highlightHotel}
                    setHighlightHotel={setHighlightHotel}
                  />
                : <NoHotelFound />
              }
              </>
          }
        </div>
      </div>

    </section>
  )
}

export default Search
