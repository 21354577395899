import React from 'react';
import * as actions from '../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import ListBoxCity from "./ListBoxCity";
import ListBoxNights from "./ListBoxNights";
import SearchBarBusinessSwitch from "./SearchBarBusinessSwitch";

const SearchBar = () => {

  const dispatch = useDispatch()

  const cityList = useSelector(state => state.search.cityList)
  const nightList = useSelector(state => state.search.nightListCurrentProduct)
  const inputCity = useSelector(state => state.search.inputCity)
  const inputNights = useSelector(state => state.search.inputNights)
  const searchProduct = useSelector(state => state.search.searchProduct)
  const isBusiness = (searchProduct === 'business')

  const handleCityChange = (value) => {
    dispatch(actions.updateInputCity(value.cityId))
    dispatch(actions.updateSearchCity(value.cityId))
    dispatch(actions.setSearchUrl())
  }

  const handleNightsChange = (value) => {
    dispatch(actions.updateInputNights(value.n))
    dispatch(actions.updateSearchNights(value.n))
    dispatch(actions.setSearchUrl())
  }

  return (
    <div id="search" className="-mt-1">

      <div className="flex items-center justify-center">
        <SearchBarBusinessSwitch />
      </div>

      <div className="flex justify-center">

        <div className="flex flex-wrap items-center xl:justify-center bg-gray-100 rounded-lg p-0 shadow-md z-10">

          <div className="bg-gray-100 py-2 px-3 mx-1 flex items-center w-full sm:w-52">
            <ListBoxCity data={cityList} searchCity={inputCity} setSearchCity={handleCityChange} />
          </div>

          <div className="bg-gray-100 px-3 mx-1 flex items-center w-full sm:w-56">
            <ListBoxNights data={nightList} searchNights={inputNights} addFrom={isBusiness} setSearchNights={handleNightsChange} />
          </div>

        </div>

      </div>

    </div>
  )

}

export default SearchBar
