import Invoice from "../assets/svg/Invoice";
import React from "react";
import Tippy from "@tippyjs/react";
import {Info} from "@mui/icons-material";

const BusinessInvoicingHelp = ({help=true}) => {
  return(
    <div className="mt-5 flex items-center text-teal-700">
      <Invoice className="w-7 h-7 mr-1" />
      <div>
        <div className="font-bold flex items-center">
          Monatliche Rechnung
          {help
            ? <Tippy theme="light" content={
              <div>
                Am Monatsende erhaltet ihr eine Rechnung über alle Buchungen
              </div>
            }><Info className="ml-1 text-teal-700"/>
            </Tippy>
            : ''
          }
        </div>
        <div className="font-light text-sm">
          Keine Zahlung im Hotel notwendig
        </div>
      </div>
    </div>
  )
}

export default BusinessInvoicingHelp