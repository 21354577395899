import React, {Fragment} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {ChevronUpDownIcon} from '@heroicons/react/20/solid'


const ListBoxNights = (props) => {

  const {data, searchNights, setSearchNights, addFrom} = props
  const searchNightsScope = data?.find(x => x?.n === searchNights)?.s || null

  return (
    <div className="w-full sm:w-52">
      <Listbox value={searchNights} onChange={setSearchNights}>
        <div className="relative">
          <Listbox.Button className="relative w-full cursor-default rounded-lg py-2 pl-1 pr-8 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-primary sm:text-sm noShadow">
            <span className="block truncate font-bold">{addFrom ? <span className="font-light text-sm text-gray-800 mr-0.5">ab</span>: ''} {searchNights} Nächte<span className="font-light text-sm text-gray-800 ml-1">/ {searchNightsScope === 'year' ? 'Jahr' : 'Monat'}</span></span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto z-50 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {data.map((element, elementIdx) => (
                <Listbox.Option
                  key={elementIdx}
                  className={({ searchNights, active }) =>
                    `relative cursor-default select-none py-2 px-4 ${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                    } ${
                      searchNights ? 'bg-gray-100' : 'text-gray-900'
                    }`
                  }
                  value={element}
                >
                  {({ searchNights }) => (
                    <>
                      <span
                        className={`block truncate ${
                          searchNights ? 'text-secondaryBlue' : 'text-gray-900'
                        }`}
                      >
                        {addFrom ? 'ab ': ''} {element.n} Nächte<span className="font-light text-sm ml-1">/ {element.s === 'year' ? 'Jahr' : 'Monat'}</span>
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default ListBoxNights
